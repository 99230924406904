<template>
	<div>
		<div class="row align-items-center" style="height:60px;">
			<div class="col-md-12 d-flex align-items-center">
				<input
					type="text"
					class="input_default form-control"
					placeholder="novo comentario ..."
					v-model="newComent"
				/>
				<i class="fas fa-plus-circle ml-3" style="color:#E0A638;" @click.prevent="sendNewComent()"></i>
			</div>
		</div>
		<transition-group name="list-complete" tag="div">
			<div
				class="row align-items-center list-complete-item"
				style="height:60px;"
				v-for="(item,index) in comments"
				:key="index + 1"
			>
				<div class="col-md-12 d-flex align-items-center">
					<div class="input-group">
						<div class="input-group-prepend">
							<div class="input-group-text">{{getDate(item.creationTime)}}</div>
						</div>

						<input
							type="text"
							class="input_default form-control inputData pl-0"
							:value="item.comment"
							readonly
						/>
						<div class="input-group-prepend">
							<div class="input-group-text">@{{item.userComment}}</div>
						</div>
					</div>
					<i
						class="fas fa-times-circle ml-3"
						style="color:#E67979;"
						@click.prevent="deleteComent(item.id)"
					></i>
				</div>
			</div>
		</transition-group>
	</div>
</template>

<script>
	import HTTP from "@/api_system";
	export default {
		props: ["comments", "id"],
		data() {
			return {
				newComent: "",
			};
		},
		mounted() {},
		methods: {
			sendNewComent() {
				if (this.newComent != "") {
					HTTP.post(
						"services/app/CommentLogbook/CreateCommentLogBook",
						{ logBookId: this.id, comment: this.newComent },
						{
							headers: {
								Authorization: `Bearer ${this.$store.getters.getterToken}`,
							},
						}
					).then((response) => {
						this.$emit("getLogs");
						this.newComent = "";
					});
				}
			},
			deleteComent(val) {
				HTTP.delete(`services/app/CommentLogbook/Delete?Id=${val}`, {
					headers: {
						Authorization: `Bearer ${this.$store.getters.getterToken}`,
					},
				}).then((response) => {
					this.$emit("getLogs");
					this.$notify({
						group: "erros",
						type: "error",
						text: `<i class="icon ion-close-circled"></i>Comentário excluida`,
					});
				});
			},
			getDate(val) {
				var data = new Date(val);
				return `${data.getDate()}/${data.getMonth() + 1}/${data.getFullYear()}`;
			},
		},
	};
</script>

<style scoped>
.input_default[readonly="readonly"] {
	pointer-events: none;
}
.input_default {
	height: 45px;
	color: #ffffff;
	text-align: left;
	background-color: #6198c2 !important;
}
.input_default::placeholder {
	color: #ffffff;
}
.input-group-text {
	color: #175d93;
	font-size: 14px;
	background-color: #6198c2;

	border: 0;
	font-weight: 500;
}
.inputData {
	height: auto;
	border: none;

	border-radius: 0px !important;
}
.input-group {
	height: 45px !important;
	border: 1px solid #e3e3e3;
	border-radius: 7px !important;
	overflow: hidden;
}
.fas {
	font-size: 22px;
	cursor: pointer;
}
</style>
