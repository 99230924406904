
<template>
	<div class="diarioDeBordo py-5">
		<div class="welcomeMobile" v-if="this.sizeWindow <= 767">
			<router-link to="/" class="imgWelCont">
				<img src="../assets/image/iconesMobile/grayArrow.svg" class="grayArrow">
			</router-link>
			<div class="txtWelCont">
				<p>Diário de Bordo</p>
			</div>
		</div>
		<div class="container">
			<div class="col-md-12" :style="load ? 'height:250px;':'min-height:100%;'">
				<loader color="#e0a638" size="100" v-if="load" />
				<div class="col-md-12" v-else>
					<div class="col-md-12 my-3">
						<div class="row align-items-center">
							<div class="col-md-2 px-0" v-if="this.sizeWindow > 767">
								<p class="titulo_dash mb-0" >Add Novo diário</p>
							</div>
							<div class="col-md-2 ml-auto pr-0">
								<button
									class="btn_default d-flex justify-content-center align-items-center"
									id="newObserver"
									@click.prevent="showObs()"
								>
									<i class="fas fa-plus-circle mr-1"></i>Add Observação
								</button>
							</div>
						</div>
					</div>
					<div class="col-md-12" v-if="sizeWindow > 767">
						<div class="row" v-if="log !=''">
							<div class="col-md-2">
								<p class="titulo_dash mb-0 nav">Data</p>
							</div>
							<div class="col-md-2">
								<p class="titulo_dash mb-0 nav">Usuário</p>
							</div>
							<div class="col-md-2">
								<p class="titulo_dash mb-0 nav">Observações</p>
							</div>
						</div>
						<div v-else>
							<p
								class="my-5 text-center w-100"
								style="font-size:16px; color:#ffff;"
							>Ainda não existem observações cadastradas</p>
						</div>
					</div>
					<observacao class="observer" @getLogs="getLogs" @showObs="showObs" />
					<transition-group name="list-complete" tag="div">
						<div class="list-complete-item" v-for="(item , index) in log" :key="index + 1">
							<div class="col-md-12 box py-2 my-2">
								<div :class= "sizeWindow < 768 ? 'row align-items-center h-100' : '' ">
									<div v-if="sizeWindow > 767" class="row align-items-center mt-3">
										<div class="col-md-2">
											<p class="descricao mb-0">{{getDate(item.date)}}</p>
										</div>
										<div class="col-md-2">
											<p class="descricao mb-0">{{item.user}}</p>
										</div>
										<div class="col-md-2">
											<p class="descricao mb-0" v-show="active != index">{{item.note}}</p>
										</div>
									</div>
									<div v-if="sizeWindow <= 767" style="max-width:100%">
										<div class="col-md-2" id="infoBoardCont">
											<div>
												<p class="titleDesc">Usuário: </p>
											</div>
											<div>
												<p class="descricao mb-0"> {{ item.user}}</p>
											</div>
										</div>
										<div class="col-md-2" id="infoBoardCont">
											<div>
												<p class="titleDesc">Observação: </p>
											</div>
											<p class="descricao mb-0" v-show="active != index"> {{ item.note}}</p>
										</div>
										<div class="col-md-2" id="infoBoardCont">
											<div>
												<p class="titleDesc">Data: </p>
											</div>
											<div>
												<p class="descricao mb-0"> {{ getDate(item.date)}}</p>
											</div>
										</div>
									</div>
									<div class="col-md-5 ml-auto">
										<div class="row alinhamento">
											<div class="px-0 text-right ml-auto mx-2 d-flex align-content-center flex-column align-items-center" id="iconAndTxtBoardCont">
												<p class="titleDesc">Observações</p>
												<div class="iconBoardCont"><i
													class="far fa-comment-alt"
													@click.prevent="showDp(index)"
													:class="active == index ? 'active' :''"
												></i></div>
											</div>

											<div class=" px-0 text-right mx-2 d-flex align-content-center flex-column align-items-center" id="iconAndTxtBoardCont">
												<p class="titleDesc">Editar</p>
												<div class="iconBoardCont"><i
													class="fas fa-pen"
													:id="`teste${index}`"
													@click.prevent="showEdit(index)"
													:class="activeEdit == index ? 'active' :''"
												></i></div>
											</div>

											<div class=" px-0 text-right mx-2 d-flex align-content-center flex-column align-items-center" id="iconAndTxtBoardCont">
												<p class="titleDesc">Excluir</p>
												<div class="iconBoardCont"><i class="fas fa-times-circle delete" @click.prevent="deleteLog(item.id)"></i></div>
											</div>
										</div>
									</div>
								</div>
								<p class="dropdownCp hidenObserve" :class="`dropdownCp${index}`">{{item.note}}</p>
								<dropdown
									class="dropdownCp"
									:class="`dropdownCp${index}`"
									:comments="item.comments"
									:id="item.id"
									@getLogs="getLogs"
								/>
							</div>
							<newNote
								@getLogs="getLogs"
								@showObs="showObs"
								:note.sync="item.note"
								:id.sync="item.id"
								:activeEdit.sync="activeEdit"
								class="editItem"
								:class="`editItem${index}`"
							/>
						</div>
					</transition-group>
				</div>
			</div>
		</div>
		<modalDegustacao/>
	</div>
</template>

<script>
import dropdown from '@/components/diario/dropdown'
import observacao from '@/components/diario/newObserver'
import newNote from '@/components/diario/newNote'
import modalDegustacao from "@/components/modal/modalDegustacao"
import HTTP from '@/api_system'
import loader from '@/components/utils/_Load'
export default {
  components: {
    dropdown,
    observacao,
    loader,
    newNote,
	modalDegustacao
  },
  data () {
    return {
      active: null,
      activeEdit: null,
      log: [],
      load: true,
      sizeWindow: 0
    }
  },
  mounted () {
    this.sizeWindow = $(window).width()
    this.getLogs()
  },
  methods: {
    showDp (index) {
      if ($('.dropdownCp').is(':visible')) {
        $('.dropdownCp').velocity('slideUp', {
          display: 'none',
          duration: 500
        })
      }
      var show
      if ($(`.dropdownCp${index}`).is(':visible')) {
        show = 'slideUp'
        this.active = null
      } else {
        show = 'slideDown'
        this.active = index
      }

      $(`.dropdownCp${index}`).velocity(show, {
        display: `${show == 'slideUp' ? 'none' : 'block'}`,
        duration: 500
      })
    },

    showObs () {
		  if (this.$store.getters.get_tenant.type == 4 && this.$store.getters.getUser.profile != 1) {
        $("#modalDegustacao").modal("show");;
      } else {
        var show
        this.activeEdit = null
        $('.editItem').velocity('fadeOut', {
          display: 'none',
          duration: 500
        })
        if ($(`.observer`).is(':visible')) {
          show = 'fadeOut'
        } else {
          show = 'fadeIn'
        }
        $(`.observer`).velocity(show, {
          display: `${show == 'fadeOut' ? 'none' : 'flex'}`,
          duration: 500
        })
      }
    },
    showEdit (index) {
	    if (this.$store.getters.get_tenant.type == 4 && this.$store.getters.getUser.profile != 1) {
        $("#modalDegustacao").modal("show");
      } else {
        $('.observer').velocity('fadeOut', {
          display: 'none',
          duration: 500
        })
        if ($('.editItem').is(':visible')) {
          $('.editItem').velocity('fadeOut', {
            display: 'none',
            duration: 500
          })
        }
        var show
        if ($(`.editItem${index}`).is(':visible')) {
          show = 'fadeOut'
          this.activeEdit = null
        } else {
          show = 'fadeIn'
          this.activeEdit = index
        }

        $(`.editItem${index}`).velocity(show, {
          display: `${show == 'fadeOut' ? 'none' : 'flex'}`,
          duration: 500
        })
      }
    },
    getLogs () {
      HTTP.get('services/app/LogBook/GetLogbookForList', {
        headers: {
          Authorization: `Bearer ${this.$store.getters.getterToken}`
        }
      })
        .then((response) => {
          this.load = false
          this.log = response.data.result
        })
        .catch((error) => {
          this.load = false
          this.$notify({
            group: 'erros',
            type: 'error',
            text: `<i class="icon ion-close-circled"></i>Ops algo deu errado`
          })
        })
    },
    deleteLog (val) {
      if (this.$store.getters.get_tenant.type == 4 && this.$store.getters.getUser.profile != 1) {
        $("#modalDegustacao").modal("show");
      } else {
        HTTP.delete(`services/app/LogBook/Delete?Id=${val}`, {
        headers: {
          Authorization: `Bearer ${this.$store.getters.getterToken}`
        }
        }).then((response) => {
          this.getLogs()
          this.$notify({
            group: 'erros',
            type: 'error',
            text: `<i class="icon ion-close-circled"></i>Observação excluida`
          })
        })
      }
    },

    getDate (val) {
      return moment(val).format('DD/MM/YYYY')
    }
  }
}
</script>

<style scoped>
.editItem {
	display: none;
}
.delete:hover {
	color: rgb(230, 121, 121) !important;
}
.btn_default {
	height: 40px;
	background-color: #e0a638 !important;
	border: none !important;
}
.btn_default:hover {
	border: none !important;
	background-color: #f38235 !important;
}
.h-50px {
	height: 50px;
}
.btn_default i {
	color: #fff !important;
}
.dropdownCp {
	display: none;
}
.observer {
	display: none;
}
.diarioDeBordo {
	background: #2474b2;
}
.nav {
	font-size: 12px;
}
.box {
	background: #357eb8;
	box-shadow: 0px 3px 6px 0 rgba(68, 68, 68, 0.3);
}
.descricao {
	color: #fff;
	font-weight: 400;
	font-size: 14px;
	overflow: hidden;
	text-overflow: ellipsis;
	min-height: 17px;
	white-space: nowrap;
}
.hidenObserve {
	color: #fff;
	font-weight: 400;
	font-size: 14px;
}
.active {
	color: #fff !important;
}
.fas,
.far {
	color: #175d93;
	font-size: 20px;
	cursor: pointer;
	transition: all 0.2s linear;
}

.far:hover {
	color: #fff;
}
.fas:hover {
	color: #fff;
}
.alinhamento {
	padding-right: 15px;
	padding-left: 15px;
}
@media screen and (max-width:767px){

  .welcomeMobile{
	  display: flex;
	  align-items: center;
	  justify-content: flex-start;
	  width: 100%;
	  background: #217ABF;
	  height: 40px;
	  margin-bottom: 3%;
	}
	.imgWelCont{
	  width: 44%;
	  display: flex;
	  justify-content: flex-start;
	  align-items: center;
	}
	.txtWelCont{
	  width: 77%;
	  display: flex;
	  justify-content: flex-start;
	  align-items: center;
	}
	.txtWelCont p{
	  margin-bottom: 0;
	  font-style: normal;
	  font-weight: 700;
	  font-size: 16px;
	  line-height: 24px;
	  color: #FFFFFF;
	}
	.imgWelCont img{
	  padding-left: 7%;
	  width: 28%;
	}
	.diarioDeBordo{
		padding-top: 0!important;
	}
   #infoBoardCont{
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		width: 100%;
   	}
	   #infoBoardCont p{
		margin-bottom: 0!important;
		color: #fff;
		margin-bottom: 5px!important;
   	}
	   .descricao{
		color: #fff;
		font-weight: 400;
		font-size: 13px;
		overflow: hidden;
		text-overflow: ellipsis;
		min-height: auto;
		white-space: nowrap;
		margin-left: 2px;
   	}
	.titleDesc{
		font-size: 15px;
		color: #FFFFFF;
		margin-bottom: 5px!important;
	}
	.fas {
		color: #FFFF!important;
	}
	.far {
		color: #FFFF!important;
	}
	.active{
		color: #175d93!important;
	}
	.box{
		border-radius: 8px;
	}
	#iconAndTxtBoardCont{
		display: flex;
		width: 100%;
		justify-content: space-between;
		align-items: center;
		min-height: 38px;
		margin-top: 5px;
		margin-bottom: 5px;
	}
	.iconBoardCont{
		width: 50%;
		height: 100%;
		background: #5397CC;
		border-radius: 4px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
  }
</style>
